<template>
  <div id="app">
    <keep-alive>
      <router-view class="AppView" v-if="this.$route.meta.KeepAlive"/>
    </keep-alive>
    <router-view class="AppView" v-if="!this.$route.meta.KeepAlive"/>
  </div>
</template>

<script>
import './assets/pageCss/base.css'
export default {
  name: 'app',
  computed: {
    MusicIndex () {
      return this.$store.state.activeMusic
    }
  },
  watch: {
    MusicIndex (n, o) {
      this.$music.setAttribute('src', this.$store.state.CurrentMusic.musicHref)
      this.$music.play()
    }
  },
  mounted () {
    console.log(this.$route.meta.KeepAlive)
  }
}
</script>

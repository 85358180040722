import store from '@/store'

export function CreateMusic () {
  var playMusics = document.createElement('audio')
  playMusics.setAttribute('id', 'Music')
  playMusics.setAttribute('preload', 'auto')

  // 播放音乐
  playMusics.onplay = () => {
    store.commit('PlayMusicStatus')
  }

  // 开始播放音乐
  playMusics.onloadedmetadata = () => {
    store.commit('RefreshTotleTime', playMusics.duration)
  }

  // 停止播放函数
  playMusics.onpause = () => {
    store.commit('PauseMusicStatus')
  }

  // 音乐进程实时同步更新
  playMusics.ontimeupdate = () => {
    store.commit('RefreshProcess', playMusics.currentTime)
  }

  // 音乐结束
  playMusics.onended = () => {
    store.commit('NextSong')
  }

  return playMusics
}

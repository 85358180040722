import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/', // 默认路由
    redirect: 'Home'
  },
  {
    path: '*', // 路由不存在
    redirect: '/404'
  },
  {
    path: '/404',
    name: 'notFound',
    component: () => import('../components/NotFound.vue'),
    meta: {
      TabbarNavShow: false,
      MusicContrShow: false,
      PageName: 'notFound',
      KeepAlive: true
    }
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import('../views/Home/HomeIndex.vue'),
    meta: {
      TabbarNavShow: true,
      MusicContrShow: true,
      PageName: '首页',
      KeepAlive: true
    }
  },
  {
    path: '/My',
    name: 'My',
    component: () => import('../views/My/MyInformation.vue'),
    meta: {
      TabbarNavShow: true,
      MusicContrShow: true,
      PageName: '个人中心',
      KeepAlive: false
    }
  },
  {
    path: '/MV',
    name: 'MV',
    component: () => import('../views/MV/MusicVideoView.vue'),
    meta: {
      TabbarNavShow: true,
      MusicContrShow: false,
      PageName: 'MV',
      KeepAlive: false
    }
  },
  {
    path: '/Login/:token?',
    name: 'Login',
    component: () => import('../views/Account/LoginView.vue'),
    meta: {
      TabbarNavShow: false,
      MusicContrShow: false,
      PageName: '登录',
      KeepAlive: true
    }
  },
  {
    path: '/Register',
    name: 'Register',
    component: () => import('../views/Account/RegisterView.vue'),
    meta: {
      TabbarNavShow: false,
      MusicContrShow: false,
      PageName: '注册'
    }
  },
  {
    path: '/Community',
    name: 'Community',
    component: () => import('../views/Community/CommunityIndex.vue'),
    meta: {
      TabbarNavShow: true,
      MusicContrShow: true,
      PageName: '社区',
      KeepAlive: true
    }
  },
  {
    path: '/Search/:Condition?',
    name: 'Search',
    component: () => import('../views/Search/SearchIndex.vue'),
    meta: {
      TabbarNavShow: false,
      MusicContrShow: true,
      PageName: '搜索',
      KeepAlive: false
    }
  },
  {
    path: '/ListInfo/:Condition?',
    name: 'ListInfo',
    component: () => import('../views/SongList/MusicListIndex.vue'),
    meta: {
      TabbarNavShow: false,
      MusicContrShow: true,
      PageName: '歌曲列表',
      KeepAlive: false
    }
  },
  {
    path: '/MusicDetails',
    name: 'MusicDetails',
    component: () => import('../views/MusicDetails/MusicDetails.vue'),
    meta: {
      TabbarNavShow: false,
      MusicContrShow: false,
      PageName: '歌曲详情',
      KeepAlive: false
    }
  },
  {
    path: '/Settings',
    name: 'Settings',
    component: () => import('../views/Settings/SettingsIndex.vue'),
    meta: {
      TabbarNavShow: false,
      MusicContrShow: true,
      PageName: '设置',
      KeepAlive: true
    }
  },
  {
    path: '/SongSheet',
    name: 'SongSheet',
    component: () => import('../views/SongSheet/SongSheetIndex.vue'),
    meta: {
      TabbarNavShow: false,
      MusicContrShow: true,
      PageName: '我的歌单',
      KeepAlive: false
    }
  },
  {
    path: '/SongSheetDetails',
    name: 'SongSheetDetails',
    component: () => import('../views/SongSheetDetails/SongSheetDetailsIndex.vue'),
    meta: {
      TabbarNavShow: false,
      MusicContrShow: true,
      PageName: '歌单详情',
      KeepAlive: false
    }
  },
  {
    path: '/ShareSheet',
    name: 'ShareSheet',
    component: () => import('../views/ShareSheet/ShareSheetIndex.vue'),
    meta: {
      TabbarNavShow: false,
      MusicContrShow: false,
      PageName: '分享',
      KeepAlive: false
    }
  },
  {
    path: '/ModifySongSheet',
    name: 'ModifySongSheet',
    component: () => import('../views/SongSheetDetails/ModifySongSheet.vue'),
    meta: {
      TabbarNavShow: false,
      MusicContrShow: false,
      PageName: '编辑歌单',
      KeepAlive: false
    }
  },
  {
    path: '/MusicalCriticism',
    name: 'MusicalCriticism',
    component: () => import('../views/MusicalCriticism/MusicalCriticism.vue'),
    meta: {
      TabbarNavShow: false,
      MusicContrShow: false,
      PageName: '歌曲评论',
      KeepAlive: false
    }
  },
  {
    path: '/MyMusicMedia',
    name: 'MyMusicMedia',
    component: () => import('../views/MyMusic/MyMusicMedia.vue'),
    meta: {
      TabbarNavShow: false,
      MusicContrShow: true,
      PageName: '我的音乐',
      KeepAlive: false
    }
  },
  {
    path: '/EditMusicMedia',
    name: 'EditMusicMedia',
    component: () => import('../views/MyMusic/EditMusicMedia.vue'),
    meta: {
      TabbarNavShow: false,
      MusicContrShow: true,
      PageName: '修改音乐信息',
      KeepAlive: false
    }
  },
  {
    path: '/ModifyAccountInfo',
    name: 'ModifyAccountInfo',
    component: () => import('../views/ModifyAccount/ModifyAccountInfo.vue'),
    meta: {
      TabbarNavShow: false,
      MusicContrShow: true,
      PageName: '编辑账号信息',
      KeepAlive: false
    }
  },
  {
    path: '/CollectMusic',
    name: 'CollectMusic',
    component: () => import('../views/MyMusic/CollectMusic.vue'),
    meta: {
      TabbarNavShow: false,
      MusicContrShow: true,
      PageName: '我喜欢',
      KeepAlive: false
    }
  },
  {
    path: '/UploaderMusic',
    name: 'UploaderMusic',
    component: () => import('../views/MyMusic/UploaderMusic.vue'),
    meta: {
      TabbarNavShow: false,
      MusicContrShow: true,
      PageName: '上传歌曲',
      KeepAlive: false
    }
  }
]

const router = new VueRouter({
  routes
})

// 配置路由前置导航守卫
router.beforeEach((to, from, next) => {
  var PageName = to.matched[0].meta.PageName
  if (PageName !== undefined) {
    document.title = PageName
  } else {
    document.title = '404'
  }
  document.getElementById('app').scrollTop = 0
  return next()
})

export default router

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    PlayStatus: false,
    curTime: 0,
    TotalTime: 0,
    PlayList: [],
    activeMusic: 0,
    CurrentMusic: null
  },
  getters: {
  },
  mutations: {
    // 获取鉴权认证
    GetToken (state, val) {
      state.token = val
    },
    // 全局开始播放状态
    PlayMusicStatus (state) {
      state.PlayStatus = true
    },
    // 全局停止播放状态
    PauseMusicStatus (state) {
      state.PlayStatus = false
    },
    // 切换当前播放的音乐
    CutCurrentMusic (state, val) {
      state.CurrentMusic = val
    },
    // 更新当前音乐播放进度
    RefreshProcess (state, val) {
      state.curTime = val
    },
    // 更新当前音乐总时长
    RefreshTotleTime (state, val) {
      state.TotalTime = val
    },
    // 将音乐添加进最近播放
    AddPlayList (state) {
      let isExisted = false
      if (state.CurrentMusic !== null) {
        state.PlayList.forEach((elem, index) => {
          if (elem.id === state.CurrentMusic.id) {
            state.activeMusic = index
            isExisted = true
          }
        })
        if (isExisted) {
          // console.log(state.CurrentMusic)
          state.PlayList.splice(state.activeMusic, 1)
          state.PlayList.splice(state.activeMusic, 0, state.CurrentMusic)
        } else {
          state.PlayList.push(state.CurrentMusic)
          state.activeMusic = state.PlayList.length - 1
        }
      }
      localStorage.setItem('PlayInfoList', JSON.stringify(state.PlayList))
    },
    // 获取播放列表
    GetPlayList (state, val) {
      var PlayerList = JSON.parse(localStorage.getItem('PlayInfoList'))
      if (PlayerList != null) {
        state.PlayList = PlayerList
      }
    },
    UpdatePlayActiveMusic (state, val) {
      state.CurrentMusic = state.PlayList[val]
      state.activeMusic = val
    },
    // 下一首歌
    NextSong (state, val) {
      if (state.PlayList.length === 0) {
        return false
      }
      var indexLen = state.PlayList.length - 1
      if (indexLen <= state.activeMusic) {
        state.activeMusic = 0
      } else {
        state.activeMusic++
      }
      state.CurrentMusic = state.PlayList[state.activeMusic]
    },
    // 上一首歌
    LastSong (state, val) {
      if (state.PlayList.length === 0) {
        return false
      }
      var indexLen = state.PlayList.length - 1
      if (state.activeMusic === 0) {
        state.activeMusic = indexLen
      } else {
        state.activeMusic--
      }
      state.CurrentMusic = state.PlayList[state.activeMusic]
    },
    // 删除列表歌曲
    DelPlayMusic (state, data) {
      var PlayerList = JSON.parse(localStorage.getItem('PlayInfoList'))
      var playIndex = 0
      PlayerList.forEach((elem, index) => {
        if (elem.id === data.id) {
          playIndex = index
        }
      })
      PlayerList.splice(playIndex, 1)
      state.PlayList = PlayerList
      localStorage.setItem('PlayInfoList', JSON.stringify(PlayerList))
      if (data.id === state.CurrentMusic.id) {
        new Promise(resolve => {
          Vue.prototype.$music.pause()
          state.PlayStatus = false
          resolve()
        }).then(res => {
          // console.log(state.PlayList.length, playIndex)
          if (state.PlayList.length > playIndex) {
            state.CurrentMusic = state.PlayList[playIndex]
          } else if (state.PlayList.length === playIndex && state.PlayList.length > 0) {
            state.CurrentMusic = state.PlayList[0]
          } else {
            state.CurrentMusic = null
          }
          if (state.CurrentMusic) {
            Vue.prototype.$music.src = state.CurrentMusic.musicHref
            Vue.prototype.$music.play()
          }
        })
      }
    }
  },
  actions: {
  },
  modules: {
  }
})

// 项目部署接口路径
// const TestApi = 'https://localhost:44313/'
const TestApi = 'http://www.yeyifan.top:44313/'
// const TestApi = 'https://localhost:44313/'

// 本机测试接口路径
// const TestApi = 'http://localhost:5000/'

// 本机部署接口路径
// const TestApi = 'http://localhost:13991/'

export function RequestUrl () {
  return TestApi
}
